<template>
  <TitleCoaching title="Gérer mes disponibilités" />
  <Availability />
</template>

<script>
import TitleCoaching from "@/components/Coaching/TitleCoaching";
import Availability from "@/components/Coaching/Availability";
export default {
  name: "AvailabilityView",
  components: {Availability, TitleCoaching}
}
</script>

<style scoped>

</style>