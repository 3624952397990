import {default as apiAxiosInstance} from "@/services/axios/api.axios";
import apiHelper from "@/helpers/api";

class WorkingTimeSlotsService {
    getWorkingTimeSlots() {
        return apiAxiosInstance.get(apiHelper.workingTimeSlotsEndpoint);
    }
    deleteWorkingTimeSlots(id) {
        return apiAxiosInstance.delete(apiHelper.workingTimeSlotsDeleteEndpoint.replace('{id}', id));
    }
    addWorkingTimeSlots(day, startsAt, endsAt) {
        let params = {
            'day': day,
            'starts_at': startsAt,
            'ends_at': endsAt
        }
        return apiAxiosInstance.post(apiHelper.workingTimeSlotsEndpoint, params);
    }
}

export default new WorkingTimeSlotsService();